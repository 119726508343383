// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_output__cJyaE {
  position: relative;
}

.styles_delete-icon__tsIFW {
  position: absolute;
  top: 5;
  right: 5;
  z-index: 5;
}

.styles_upload-btn__OTYzY {
  width: 18vw;
}

.styles_input-error__dSHCW {
  font-size: 1vw !important;
  color: red !important;
  margin: 5px 3px;
}`, "",{"version":3,"sources":["webpack://./src/Components/utils/ImageUpload/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".output {\n  position: relative;\n}\n\n.delete-icon {\n  position: absolute;\n  top: 5;\n  right: 5;\n  z-index: 5;\n}\n\n.upload-btn {\n  width: 18vw;\n}\n\n.input-error {\n  font-size: 1vw !important;\n  color: red !important;\n  margin: 5px 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"output": `styles_output__cJyaE`,
	"delete-icon": `styles_delete-icon__tsIFW`,
	"upload-btn": `styles_upload-btn__OTYzY`,
	"input-error": `styles_input-error__dSHCW`
};
export default ___CSS_LOADER_EXPORT___;
