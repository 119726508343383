import { IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import CustomDialog from "../Atoms/CustomDialog";
import MyButton from "../Atoms/Button";
import ProjectFilter from "./Filter";
import ProjectList from "./ProjectList";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import IndustryType from "./IndustryType";
import { fetchProjects } from "../../lib/api/project/published";
import { fetchUnpublishedProjects } from "../../lib/api/project/unpublished";

let isFirstRun = true;

const Projects = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [projects, setProjects] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [industryTypeList, setIndustryTypeList] = useState(false);
  const [projectFilter, setProjectFilter] = useState({
    industryType: null,
    isGlobal: null,
    isDescending: false,
    isUnpublishedList: false,
    searchTerm: "",
  });

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  const handleCloseCreateProject = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpenIndustryType = () => {
    setIndustryTypeList(true);
  };
  const handleCloseIndustryType = () => {
    setIndustryTypeList(false);
  };

  const handleFetchData = async (payload) => {
    try {
      const filter = payload || projectFilter;
      setLoading(true);
      const response = filter?.isUnpublishedList
        ? await fetchUnpublishedProjects(filter)
        : await fetchProjects(filter);

      setProjects(response?.data);
    } catch (error) {
      setProjects([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    isFirstRun = true;
    const isUnpublishedList = searchParams.get("type") === "unpublished";
    setProjectFilter({ ...projectFilter, isUnpublishedList });
    handleFetchData({ ...projectFilter, isUnpublishedList });
  }, []);

  useEffect(() => {
    if (!isFirstRun) {
      handleFetchData(projectFilter);
    }
    isFirstRun = false;
  }, [projectFilter]);

  return (
    <>
      {open && (
        <CreateProject
          open={open}
          handleClose={handleCloseCreateProject}
          setCurrentPage={setCurrentPage}
        />
      )}

      {industryTypeList && (
        <IndustryType
          open={industryTypeList}
          // user={props.user}
          handleClose={handleCloseIndustryType}
          // setCurrentPage={setCurrentPage}
        />
      )}

      <div className={styles["container-main"]}>
        <div className={styles["top-container"]}>
          <div className={styles["modification-container"]}>
            <p>Modification</p>
            <div>
              <MyButton onClick={handleOpenIndustryType}>
                Industry Types
              </MyButton>
            </div>
          </div>

          <img
            src="/assets/images/company_logo.png"
            alt="logo"
            className={styles.logo}
          />
          <div className={styles["loguot-container"]}>
            <MyButton onClick={handleLogout}>
              <LogoutIcon />
              Logout
            </MyButton>
          </div>
        </div>
        <div className={styles["contact-btn"]}>
          <Link to="/contact">
            <MyButton>Contact</MyButton>
          </Link>
        </div>

        <div className={styles["profile-container"]}>
          <div className={styles["profile-and-text"]}>
            <Link to="/profile">
              <div className={styles["profile-img"]}>
                <img src="/assets/images/temp/profile.png" fill alt="profile" />
              </div>
            </Link>

            <ListTypeToggle
              isUnpublishedList={projectFilter.isUnpublishedList}
              onChange={(val) =>
                setProjectFilter({ ...projectFilter, isUnpublishedList: val })
              }
            />
          </div>
          <MyButton onClick={handleClickOpen}>Create Project</MyButton>
        </div>
        <ProjectFilter value={projectFilter} onChange={setProjectFilter} />

        <ProjectList
          data={projects}
          loading={loading}
          handleRefetch={handleFetchData}
          isUnpublishedList={projectFilter.isUnpublishedList}
          setProjectFilter={setProjectFilter}
        />
      </div>
    </>
  );
};

export default Projects;

const ListTypeToggle = ({ isUnpublishedList, onChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleToggle = (e) => {
    setSearchParams(new URLSearchParams());
    onChange(e.target.id === "unpublished");
  };
  return (
    <div className={styles["list-toggle"]}>
      <h2
        className={!isUnpublishedList && styles["selected"]}
        id="published"
        onClick={handleToggle}
      >
        PUBLISHED PROJECTS
      </h2>
      <h2
        className={isUnpublishedList && styles["selected"]}
        id="unpublished"
        onClick={handleToggle}
      >
        UNPUBLISHED PROJECTS
      </h2>
    </div>
  );
};

const CreateProject = ({ open, handleClose }) => {
  const [isGlobal, setIsGlobal] = useState(false);
  // const router = useRouter();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    {
      isGlobal ? navigate(`/create?isGlobal=true`) : navigate("/create");
    }
    // navigate("/create");
  };

  return (
    <>
      <CustomDialog className="my-mui-dialog" open={open} onClose={handleClose}>
        <div className={styles["dialog-container"]}>
          <IconButton
            // disabled={loading}
            onClick={handleClose}
            style={{ position: "absolute", right: "10px", color: "#000" }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <h1>Create Project</h1>
          <div className={styles["form"]}>
            <label>Select Project Type</label>
            <div className={styles["radio-container"]}>
              <span>
                <input
                  type="radio"
                  name="templateType"
                  checked={!isGlobal}
                  onChange={() => setIsGlobal(false)}
                  className={styles["radio"]}
                />
                Domestic Project
              </span>
              <span>
                <input
                  checked={isGlobal}
                  onChange={() => setIsGlobal(true)}
                  type="radio"
                  name="templateType"
                />
                Global Project
              </span>
            </div>
            <div className={styles["btn-container"]}>
              <MyButton onClick={handleSubmit}>Select</MyButton>
            </div>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};
