import React from "react";
import RestorePassword from "../../../Components/Auth/Login/restorPassword";

const RestorePasswordPage = () => {
  return (
    <div>
      <RestorePassword />
    </div>
  );
};

export default RestorePasswordPage;
