import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/global.css";
import HomePage from "./Pages/Home/home";
import Create from "./Pages/CreateProject/create";
import RestorePasswordPage from "./Pages/Auth/RestorePassword/restorePassword";
import ContactPage from "./Pages/Contact/contact";
import axios from "axios";
import { getLocalStorageWithExpiry } from "./helper/storage";
const LoginPage = lazy(() => import("./Pages/Auth/Login/login"));
const MyAccountPage = lazy(() => import("./Pages/MyAccount/myAccount"));

function App() {
  axios.interceptors.request.use(
    (config) => {
      const user = getLocalStorageWithExpiry("user");

      if (user?.accessToken) {
        config.headers["Authorization"] = "Bearer " + user?.accessToken;
      }
      // config.headers['Content-Type'] = 'application/json';
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/dashboard" element={<HomePage />} />
          <Route path="/" element={<LoginPage />} />
          <Route
            path="/auth/password/reset"
            element={<RestorePasswordPage />}
          />
          <Route path="/profile" element={<MyAccountPage />} />
          <Route path="/create" element={<Create />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
