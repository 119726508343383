import axios from "axios";

export const fetchNewsletters = async (search) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/newsletters`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { search },
    };
    const res = await axios.request(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteNewsletter = async (id) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/newsletter/delete`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { id },
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};
