import { IconButton, CircularProgress } from "@mui/material";
import styles from "./style.module.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MyButton from "../Atoms/Button";
import { Link } from "react-router-dom";
import { SearchBar } from "../Projects/Filter";
import { DeleteProjectPop } from "../Projects/ProjectList";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import CustomDialog from "../Atoms/CustomDialog";
import CloseIcon from "@mui/icons-material/Close";
import { deleteNewsletter, fetchNewsletters } from "../../lib/api/newsletter";

// Convert data to CSV format
const convertToCSV = (heading, data) => {
  const csv = data
    .map((row) => {
      return Object.values(row)
        .map((value, index) => {
          if (
            index === 4 &&
            typeof value === "string" &&
            value.match(/^\d{4}-\d{2}-\d{2}/)
          ) {
            return value.split("T")[0];
          } else if (
            typeof value === "string" &&
            (value.includes("\n") || value.includes("\r"))
          ) {
            // Replace newline characters with spaces
            value = value.replace(/[\n\r]+/g, " ");
          }
          return value;
        })
        .join(",");
    })
    .join("\n");

  return heading + csv;
};

// Download CSV file
const downloadCSV = (csvContent) => {
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "newsletter_subscriptions.csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [newsletterSubs, setNewsletterSubs] = useState([]);

  const handleFetchNewsletter = async (searchTerm) => {
    try {
      setLoading(true);
      const res = await fetchNewsletters(searchTerm || "");
      setNewsletterSubs(res?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCsv = async () => {
    try {
      const heading = "no.,name,email,message,date\n";
      const result = convertToCSV(heading, newsletterSubs);

      downloadCSV(result);
    } catch (error) {}
  };

  useEffect(() => {
    handleFetchNewsletter();
  }, []);

  return (
    <>
      <div className={styles["contact-container-main"]}>
        <div className={styles["logo-container"]}>
          <Link to="/dashboard">
            <img
              src="/assets/images/company_logo.png"
              alt="company-logo"
              className={styles.logo}
            />{" "}
          </Link>
        </div>
        <div className={styles["top-container"]}>
          <div>
            <Link to="/dashboard">
              <IconButton>
                <KeyboardBackspaceIcon style={{ color: "#000" }} />
              </IconButton>
            </Link>

            <h1>Contact Form</h1>
          </div>
          <MyButton onClick={handleDownloadCsv}>Dowonload CSV</MyButton>
        </div>
        <div className={styles["search-bar"]}>
          <SearchBar
            placeholder="Search name or email"
            value=""
            onSearch={(val) => handleFetchNewsletter(val)}
          />
        </div>

        <div className={styles["contact-info"]}>
          <div className={styles["head"]}>
            <h4>Person Name </h4>
            <h4>Email Address</h4>
            <h4>Client Message</h4>
            <h4>Created Date</h4>
            <th></th>
          </div>
          <div className={styles["body"]}>
            {loading ? (
              <CircularProgress size={20} style={{ color: "var(--red)" }} />
            ) : (
              Array.isArray(newsletterSubs) &&
              newsletterSubs.map((newsletter, i) => (
                <SingleRow
                  key={i}
                  newsletter={newsletter}
                  handleRefetch={handleFetchNewsletter}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;

const SingleRow = ({ newsletter, handleRefetch }) => {
  const [viewMessage, setViewMessage] = useState(false);

  const handleViewMessage = () => {
    setViewMessage(true);
  };
  const handleCloseMessage = () => {
    setViewMessage(false);
  };
  return (
    <>
      {viewMessage && (
        <ViewMessage
          open={viewMessage}
          onClose={handleCloseMessage}
          newsletter={newsletter}
        />
      )}
      <div>
        <p>{newsletter?.name}</p>
        <p>{newsletter?.email}</p>
        <p>
          <MyButton onClick={handleViewMessage}>View Message</MyButton>
        </p>
        <p>{newsletter?.createdAt?.split("T")[0]}</p>
        <p>
          <DeleteProject instanceId={newsletter?.id} onChange={handleRefetch} />
        </p>
      </div>
    </>
  );
};

const DeleteProject = ({ instanceId, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <DeleteProjectPop
          open={open}
          handleClose={() => setOpen(false)}
          instanceId={instanceId}
          onClick={deleteNewsletter}
          onChange={onChange}
          head="Delete Info"
          confirmationNote="Are sure you want to delete this CLient Info?"
          note="**Once deleted all the Information of Client
        will permanently removed from the database"
        />
      )}
      <IconButton onClick={() => setOpen(true)}>
        <DeleteIcon style={{ color: "#EC2227" }} />
      </IconButton>
    </>
  );
};

const ViewMessage = ({ open, onClose, newsletter }) => {
  return (
    <>
      <CustomDialog open={open} onClose={onClose}>
        <div className={styles["message-dialog"]}>
          <IconButton
            onClick={onClose}
            style={{
              position: "absolute",
              right: "10px",
              color: "#000",
              top: "10px",
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <h3>Client Message</h3>
          <div className={styles["name-and-date"]}>
            <h4>{newsletter?.name}</h4>
            <p>{newsletter?.createdAt?.split("T")[0]}</p>
          </div>
          <p>{newsletter?.message}</p>
        </div>
      </CustomDialog>
    </>
  );
};
