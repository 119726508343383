import React, { useEffect } from "react";
import Projects from "../../Components/Projects";
import { useNavigate } from "react-router-dom";
import { getLocalStorageWithExpiry } from "../../helper/storage";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!getLocalStorageWithExpiry("user")?.accessToken) navigate("/");
  }, []);
  return (
    <>
      <Projects />
    </>
  );
};

export default HomePage;
