import axios from "axios";

export const login = async (email, password) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/admin/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { email, password },
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (email, currentPasswd, newPasswd) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/admin/password/change`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { email, currentPasswd, newPasswd },
    };
    const res = await axios.request(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/admin/password/forgot`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { email },
    };
    const res = await axios.request(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (email, password, token) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/admin/password/reset`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { email, password, token },
    };
    const res = await axios.request(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};
