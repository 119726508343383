import { useEffect, useState } from "react";
import styles from "./style.module.css";
import MyButton from "../../Atoms/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import crypto from "crypto-js";
import { resetPassword } from "../../../lib/api/auth";

const resetPasswordSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  token: yup.string().required("Token is required"),
  password: yup
    .string()
    .required("New password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
      "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const RestorePassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({ password: "", confirmPassword: "" });
  const [isError, setIsError] = useState();
  const [loading, setLoading] = useState(false);

  const handleDataChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      await resetPasswordSchema.validate(data, {
        abortEarly: false,
      });
      const hashedPassword = crypto.SHA256(data?.password).toString();
      const res = await resetPassword(data?.email, hashedPassword, data?.token);
      localStorage.removeItem("user");
      navigate("/");
    } catch (error) {
      console.log(error, data);
      const errorsObject = error?.inner?.reduce((acc, validationError) => {
        return { ...acc, [validationError.path]: validationError.message };
      }, {});
      setIsError(errorsObject);
      setLoading(false);
    }
  };

  useEffect(() => {
    setData({
      password: "",
      confirmPassword: "",
      token: searchParams.get("token"),
      email: searchParams.get("email"),
    });
  }, []);
  return (
    <>
      <div className={styles["container-main"]}>
        <div className={styles["login-container"]}>
          <div className={styles["logo"]}>
            <img src="/assets/images/company_logo.png" alt="Propshop" />
          </div>
          <div className={styles["form-container"]}>
            <h3>Restore Password</h3>

            <form onSubmit={handleSubmit} className={styles["form"]}>
              <h6>Enter the New Password</h6>

              <div className={styles["inputs"]}>
                <input
                  name="password"
                  value={data?.password}
                  onChange={handleDataChange}
                  type="password"
                  placeholder="New Password"
                  disabled={loading}
                />
                {isError?.password && (
                  <p className={styles["input-error"]}>{isError?.password}</p>
                )}
                <input
                  name="confirmPassword"
                  value={data?.confirmPassword}
                  onChange={handleDataChange}
                  type="password"
                  placeholder="Confirm New Password"
                  disabled={loading}
                />
                {isError?.confirmPassword && (
                  <p className={styles["input-error"]}>
                    {isError?.confirmPassword}
                  </p>
                )}
              </div>

              <MyButton type="submit" disabled={loading}>
                {loading ? "Loading..." : "Save Password and Login Again"}
              </MyButton>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RestorePassword;
