import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

const CustomDialog = styled(Dialog)(
  ({ theme, maxWidth, borderRadius, responsiveMinHeight }) => ({
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: maxWidth || "44vw",
      height: "auto",
      borderRadius: borderRadius,
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: "16px",
        minHeight: responsiveMinHeight || "auto",
      },
    },
  })
);

export default CustomDialog;

export function MyDialog({ children, ...props }) {
  return <CustomDialog {...props}>{children}</CustomDialog>;
}
