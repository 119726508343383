import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./style.module.css";
import InputSharpIcon from "@mui/icons-material/InputSharp";
import { fetchIndustryTypes } from "../../../lib/api/project/industryType";

const projectTypes = ["All", "Global Projects", "Domestic Projects"];

const ProjectFilter = ({ value, onChange, disabled }) => {
  const [industryTypes, setIndustryTypes] = useState([]);

  const handleFetch = async () => {
    try {
      const response = await fetchIndustryTypes();
      setIndustryTypes(response?.data);
    } catch (error) {}
  };

  const handleChange = (e) =>
    onChange({ ...value, [e.target.name]: e.target.value });

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <div className={styles["filter-btn"]}>
      <select
        name="industryType"
        value={value.industryType}
        onChange={handleChange}
      >
        <option value={"all"}>Industry type</option>
        {Array.isArray(industryTypes) &&
          industryTypes.map((item, i) => (
            <option value={item.id} key={i} className={styles["option-style"]}>
              {item.industryTypeName}
            </option>
          ))}
      </select>
      <select
        name="isGlobal"
        value={value?.isGlobal}
        onChange={handleChange}
        disabled={disabled}
      >
        <option disabled selected>
          Project Type
        </option>
        <option value={"all"}>All</option>
        <option value={true}>Global Projects</option>
        <option value={false}>Domestic Projects</option>
      </select>

      <SearchBar
        value={value.searchTerm}
        onSearch={(val) => onChange({ ...value, searchTerm: val })}
        placeholder="Search Project By Name"
      />
    </div>
  );
};

export default ProjectFilter;

export const SearchBar = ({ value, onSearch, placeholder }) => {
  const [data, setData] = useState(value);
  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(data);
  };
  return (
    <form onSubmit={handleSearch} className={styles.searchbar}>
      <input
        value={data}
        onChange={(e) => setData(e.target.value)}
        placeholder={placeholder}
      />
      <IconButton type="submit">
        <SearchIcon />
      </IconButton>
    </form>
  );
};
