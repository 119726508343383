import axios from "axios";

export const fetchIndustryTypes = async (data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/industry-types/view`,
      headers: {},
      data,
    };
    const response = await axios.request(config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const createIndustryType = async (data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/industry-type/create`,
      header: {},
      data,
    };
    const response = await axios.request(config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const editIndustryType = async (data) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/industry-type/edit/${data.id}`,
      header: {},
      data: {
        industryTypeName: data.industryTypeName,
        // description: "jhbhjbj",
      },
    };
    const response = await axios.request(config);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
