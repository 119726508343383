import { CircularProgress, Dialog, IconButton, Switch } from "@mui/material";

import React, { useState } from "react";
import styles from "./style.module.css";
import CloseIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomDialog from "../../Atoms/CustomDialog";
import MyButton from "../../Atoms/Button";
import { useNavigate } from "react-router-dom";
import {
  deleteUnpublishedProject,
  publishUnpublishedProject,
} from "../../../lib/api/project/unpublished";
import {
  deleteProject,
  switchProjectAwarded,
} from "../../../lib/api/project/published";

const ProjectList = ({
  data,
  loading,
  handleRefetch,
  isUnpublishedList,
  setProjectFilter,
}) => {
  return (
    <>
      <div className={styles["table-container"]}>
        <table>
          <thead>
            <tr>
              <th>Project Name</th> <th>Industry Type</th>
              <th>Project Type</th>
              <th>
                Created Date
                <SwapVertIcon
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setProjectFilter((val) => {
                      return { ...val, isDescending: !val.isDescending };
                    })
                  }
                />
              </th>
              <th>Last Updated</th>
              {isUnpublishedList ? (
                <th></th>
              ) : (
                <th className={styles["award-th"]}>
                  award
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setProjectFilter((val) => {
                        return { ...val, isAwarded: e.target.checked };
                      })
                    }
                  />
                </th>
              )}
              <th></th>
              <th></th>
            </tr>
          </thead>

          {loading ? (
            <div>
              <CircularProgress
                sx={{
                  margin: "2vw auto",
                  color: "var(--red)",
                }}
              />
            </div>
          ) : (
            <tbody>
              {Array.isArray(data) &&
                data.map((project, i) => (
                  <SignleRow
                    project={project}
                    key={data?.id}
                    onChange={handleRefetch}
                    isUnpublishedList={isUnpublishedList}
                  />
                ))}
            </tbody>
          )}
          {/* <div className={styles['sub-container']}>
        
          </div> */}
        </table>
      </div>
    </>
  );
};

export default ProjectList;

const SignleRow = ({
  project: projectData,
  key,
  onChange,
  isUnpublishedList,
}) => {
  const navigate = useNavigate();
  const [project, setProject] = useState(projectData);

  return (
    <>
      <tr key={key}>
        <td>{project.projectName}</td>
        <td>{project.industryTypeName} </td>
        <td>{project.isGlobal ? "Global" : "Domestic"}</td>

        {/* <td>{moment(new Date(data?.createdAt)).format("MMM Do YY")}</td>
        <td>{moment(new Date(data?.modifiedAt)).format("MMM Do YY")}</td> */}
        <td>{project.createdAt?.split(" ")?.[0]}</td>
        <td>{project.modifiedAt?.split(" ")?.[0]}</td>
        {isUnpublishedList ? (
          <td>
            <PublishProject projectID={project.id} onChange={onChange} />
          </td>
        ) : (
          <td>
            <MakeAwarded
              projectID={project.id}
              value={project?.isAwarded}
              onChange={(val) => setProject({ ...project, isAwarded: val })}
            />
          </td>
        )}
        <td>
          <DeleteProject
            projectID={project.id}
            isUnpublishedList={isUnpublishedList}
            onChange={onChange}
          />
        </td>
        <td>
          <IconButton
            onClick={() =>
              navigate({
                pathname: "/create",
                search: `?id=${project?.id}&isPublished=${!isUnpublishedList}`,
              })
            }
          >
            <EditIcon style={{ color: "#000" }} />
          </IconButton>
        </td>
      </tr>
    </>
  );
};

const MakeAwarded = ({ value, projectID, onChange }) => {
  const [loading, setLoading] = useState(false);

  const handleFeatured = async () => {
    try {
      onChange(!value);
      if (!loading) {
        setLoading(true);
        switchProjectAwarded(projectID, { isAwarded: !value });
      }
    } catch (error) {
      onChange(value);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress size={20} style={{ color: "var(--red)" }} />
      ) : (
        <Switch
          checked={value}
          onChange={handleFeatured}
          disabled={loading}
          sx={{
            "& .MuiSwitch-thumb": {
              bgcolor: "var(--red)",
              // bgcolor: "#e95f25",
            },
            // "& .MuiSwitch-thumb.Mui-checked": {
            //   bgcolor: "#e95f25",
            //   // bgcolor: "#e95f25",
            // },
            // "& .MuiSwitch-track.Mui-checked": {
            //   bgcolor: "yellow",
            //   // bgcolor: "#e95f25",
            // },
            "& .MuiSwitch-track": {
              bgcolor: "var(--red)",
              // bgcolor: "#e960256b",
            },
          }}
        />
      )}
    </>
  );
};

const PublishProject = ({ projectID, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const handlePublish = async () => {
    try {
      setLoading(!loading);
      await publishUnpublishedProject(projectID);
      onChange();
    } catch (error) {
      setLoading(false);
      setIsPopUpOpen(true);
    }
  };

  return (
    <>
      <CustomDialog
        className="my-mui-dialog"
        open={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
      >
        <div className={styles["dialog-container"]}>
          <IconButton
            onClick={() => setIsPopUpOpen(false)}
            style={{ position: "absolute", right: "10px", color: "#000" }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <h1>Incomplete Project</h1>
          <div className="form">
            <p>Required fields of project are missing</p>
            <div className={styles["btn-container"]}>
              <MyButton onClick={() => setIsPopUpOpen(false)}>Close</MyButton>
            </div>
          </div>
        </div>
      </CustomDialog>

      {loading ? (
        <CircularProgress size={20} style={{ color: "var(--red)" }} />
      ) : (
        <IconButton onClick={handlePublish}>
          <PublishIcon style={{ color: "#009607" }} />
        </IconButton>
      )}
    </>
  );
};

const DeleteProject = ({ projectID, isUnpublishedList, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <DeleteProjectPop
          open={open}
          handleClose={() => setOpen(false)}
          instanceId={projectID}
          onClick={isUnpublishedList ? deleteUnpublishedProject : deleteProject}
          onChange={onChange}
          head="Delete Project"
          confirmationNote="Are sure you want to delete this project?"
          note="**Once deleted all the contents and images stored on the server
          will permanently removed from the database"
        />
      )}
      <IconButton onClick={() => setOpen(true)}>
        <DeleteIcon style={{ color: "#EC2227" }} />
      </IconButton>
    </>
  );
};

export const DeleteProjectPop = ({
  open,
  handleClose,
  instanceId,
  onClick,
  onChange,
  head,
  note,
  confirmationNote,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      if (!loading) {
        setLoading(true);
        await onClick(instanceId);
        onChange();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CustomDialog className="my-mui-dialog" open={open} onClose={handleClose}>
        <div className={styles["dialog-container"]}>
          <IconButton
            disabled={loading}
            onClick={handleClose}
            style={{ position: "absolute", right: "10px", color: "#000" }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <h2>{head}</h2>
          <p>{confirmationNote}</p>

          <p>
            <b>{note}</b>
          </p>
          <div className={styles["btn-container"]}>
            <MyButton onClick={handleDelete} disabled={loading}>
              {loading ? "Deleting..." : "Confirm Delete"}
            </MyButton>
          </div>
        </div>
      </CustomDialog>
    </>
  );
};
