import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useRef } from "react";
// import getS3Urls from "../../../../helper/aws-S3";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./styles.module.css";
import MyButton from "../../Atoms/Button";

const ImageUpload = ({ children, value, onChange, accept, disabled, name }) => {
  const [isError, setIsError] = useState(false);
  const myRefInputFile = useRef(null);
  function ImgUpload() {
    myRefInputFile.current.click();
  }
  const handleChange = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer
      ? e.dataTransfer.files?.[0]
      : e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        onChange({ base64: e.target.result, file }); // Set the data URL of the image
      };
      reader.readAsDataURL(file); // Convert the file to data URL
    }
  };
  return (
    <div>
      <input
        ref={myRefInputFile}
        value={value}
        onChange={handleChange}
        accept={accept}
        id="file-upload"
        style={{ display: "none" }}
        type="file"
        name={name}
      />
      <MyButton
        className={styles["upload-btn"]}
        disabled={disabled}
        type="button"
        onClick={ImgUpload}
      >
        <CloudUploadSharpIcon />
        {children}
      </MyButton>
      {isError && (
        <p className={styles["input-error"]}>File should be less than 2mb*</p>
      )}
    </div>
  );
};

export default ImageUpload;

export const ImageUploadWithOutput = ({
  children,
  value,
  onChange,
  onDelete,
  accept,
  name,
}) => {
  const handleDelete = () => onDelete();
  if (value)
    return (
      <div className={styles["output"]}>
        <div className={styles["delete-icon"]}>
          <IconButton onClick={handleDelete}>
            <DeleteIcon style={{ color: "red" }} />
          </IconButton>
        </div>
        <img src={value?.base64 || value} width={100} height={100} alt="img" />
      </div>
    );

  return (
    <>
      <ImageUpload
        accept={accept}
        onChange={(url) => onChange(url)}
        name={name}
      >
        {children}
      </ImageUpload>
    </>
  );
};
