import ContactForm from "../../Components/ContactForm";

const ContactPage = () => {
  return (
    <div>
      <ContactForm />
    </div>
  );
};

export default ContactPage;
