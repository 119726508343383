import styles from "./style.module.css";
import { CircularProgress, IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useState, useEffect } from "react";
import CustomDialog from "../../Atoms/CustomDialog";
import MyButton from "../../Atoms/Button";
import { fetchIndustryTypes } from "../../../lib/api/project/industryType";
import { createIndustryType } from "../../../lib/api/project/industryType";
import { editIndustryType } from "../../../lib/api/project/industryType";
import * as yup from "yup";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const industrySchema = yup.object({
  industryTypeName: yup.string().required("Valid Industy type is required"),
});

const IndustryType = ({ open, handleClose }) => {
  const [isCreateNewType, setIsCreateNewType] = useState(false);
  const [loading, setloading] = useState(true);
  const [industryTypes, setIndustryTypes] = useState([]);

  const handleCloseCreateClinetType = () => {
    setIsCreateNewType(false);
  };
  const handleOpenCreateClinetType = () => {
    setIsCreateNewType(true);
  };

  const handleFetchData = async () => {
    try {
      setloading(true);
      const data = await fetchIndustryTypes();
      setIndustryTypes(data?.data);
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      {isCreateNewType && (
        <CreateNewType
          onChange={handleFetchData}
          open={isCreateNewType}
          handleClose={handleCloseCreateClinetType}
          type="create"
        />
      )}
      <SnackbarProvider />
      <CustomDialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <div className={styles["dialog-container"]}>
          <div className={styles["back-container"]}>
            <IconButton
              onClick={handleClose}
              style={{ position: "relative", left: "0px", color: "#000" }}
            >
              <KeyboardBackspaceIcon fontSize="large" />
            </IconButton>
            <h1>Industry Type</h1>
          </div>
          <div className={styles["add-new-btn"]}>
            <MyButton onClick={handleOpenCreateClinetType}>
              <AddIcon /> Add New Type
            </MyButton>
          </div>
          <div className={styles["types-container"]}>
            {loading ? (
              <CircularProgress style={{ color: "var(--red)" }} />
            ) : (
              Array.isArray(industryTypes) &&
              industryTypes?.map((item, i) => (
                <SingleType
                  data={item}
                  key={i}
                  handleFetchData={handleFetchData}
                />
              ))
            )}
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default IndustryType;

const SingleType = ({ data, handleFetchData }) => {
  const [edit, setEdit] = useState();
  const handleCloseEditType = () => {
    setEdit(false);
  };
  const handleOpenEditType = () => {
    setEdit(true);
  };

  return (
    <>
      {edit && (
        <CreateNewType
          value={data}
          onChange={handleFetchData}
          open={edit}
          handleClose={handleCloseEditType}
          type="edit"
        />
      )}
      <div className={styles["single-type"]}>
        <p className={styles["id"]}>{data.id}</p>
        <p className={styles["name"]}>{data.industryTypeName}</p>
        <button onClick={handleOpenEditType}>
          <ModeEditIcon />
        </button>
      </div>
    </>
  );
};

const CreateNewType = ({
  value,
  onChange,
  open,
  handleClose,
  type,
  handleEditIndustryType,
}) => {
  // const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(value || {});
  const [error, setError] = useState({});
  const [loading, setloading] = useState(false);

  const handleFormData = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const handeFormSubmit = async (e) => {
    try {
      e.preventDefault();
      setloading(true);
      await industrySchema.validate(data, {
        abortEarly: false,
      });

      if (type === "create") {
        await createIndustryType(data);

        onChange();
        handleClose();
      } else if (type === "edit") {
        await editIndustryType(data);
        onChange();
        handleClose();
      } else {
      }
    } catch (error) {
      const errorsObject = error?.inner?.reduce((acc, validationError) => {
        return { ...acc, [validationError.path]: validationError.message };
      }, {});
      setError(errorsObject);
      enqueueSnackbar(
        error?.response?.data?.msg || "Something went wrong! Please try again.",
        { variant: "error" }
      );
      setloading(false);
    }
  };

  return (
    <>
      <CustomDialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
        <div className={styles["dialog-container"]}>
          <div className={styles["back-container"]}>
            <IconButton
              onClick={handleClose}
              style={{ position: "relative", left: "0px", color: "#000" }}
              disabled={loading}
            >
              <KeyboardBackspaceIcon fontSize="large" />
            </IconButton>
            <h1>
              {type === "edit"
                ? "Edit Industry Type"
                : "Create New Industry Type"}
            </h1>
          </div>

          {type === "edit" && (
            <p className={styles["current-type"]}>
              Current type : {value?.industryTypeName}
            </p>
          )}

          <form className={styles["create-form"]} onSubmit={handeFormSubmit}>
            <label>
              {type === "edit" ? "Enter New Type" : "Enter New Industry Type"}
            </label>
            <input
              type="text"
              name="industryTypeName"
              value={data?.industryTypeName}
              onChange={handleFormData}
              placeholder="Insert type Here"
            />
            {error?.industryTypeName && (
              <p className={styles["inavlid-text"]}>
                {error?.industryTypeName}
              </p>
            )}

            <div className={styles["save-nd-cancel-btn"]}>
              <MyButton type="submit" disabled={loading}>
                {loading ? "loading..." : "Save"}
              </MyButton>

              <MyButton type="button" onClick={handleClose} disabled={loading}>
                Cancel
              </MyButton>
            </div>
          </form>
        </div>
      </CustomDialog>
    </>
  );
};
