import axios from "axios";
import { getLocalStorageWithExpiry } from "../../../helper/storage";

export const createUnpublishedProject = async (data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/project/unpublished/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const editUnpublishedProject = async (id, data) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/project/unpublished/edit/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUnpublishedProjects = async (data) => {
  try {
    if (data?.industryType === "all") {
      data.industryType = null;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/projects/unpublished/view`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          getLocalStorageWithExpiry("user")?.accessToken
        }`,
      },
      data,
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const publishUnpublishedProject = async (id, data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/project/unpublished/publish/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUnpublishedProject = async (id) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/project/unpublished/delete/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUnpublishedProject = async (id) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/project/unpublished/view/${id}`,
      headers: {},
    };

    const res = await axios.request(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};
