import axios from "axios";

export const createProject = async (data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/project/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const editProject = async (id, data) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/project/edit/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProject = async (id) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/project/view/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProjects = async (data) => {
  try {
    if (data?.industryType === "all") {
      data.industryType = null;
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/projects/view`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const switchProjectAwarded = async (id, data) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/project/awarded/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProject = async (id) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/project/delete/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.request(config);

    return res?.data;
  } catch (error) {
    throw error;
  }
};
