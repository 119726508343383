import styles from "./style.module.css";

const MyButton = ({ children, className, ...props }) => {
  return (
    <button className={`${className} ${styles["btn"]}`} {...props}>
      {children}
    </button>
  );
};

export default MyButton;
