import { s3, s3BucketName } from "../../helper/aws_s3";

const checkIsNotUploaded = (str) => {
  return str !== null && typeof str === "object";
};

const getImagesToUpload = (project) => {
  try {
    const toUpload = [];
    const tempProject = { ...project };
    let count = 0;

    if (checkIsNotUploaded(tempProject?.featuredImage)) {
      toUpload.push(tempProject.featuredImage);
      tempProject.featuredImage = count++;
    }

    if (checkIsNotUploaded(tempProject?.bannerImage)) {
      toUpload.push(tempProject.bannerImage);
      tempProject.bannerImage = count++;
    }
    if (checkIsNotUploaded(tempProject?.keyHighlightImage)) {
      toUpload.push(tempProject.keyHighlightImage);
      tempProject.keyHighlightImage = count++;
    }
    if (checkIsNotUploaded(tempProject?.mainImage)) {
      toUpload.push(tempProject.mainImage);
      tempProject.mainImage = count++;
    }
    Array.isArray(tempProject.thumbnailImages) &&
      tempProject.thumbnailImages?.map((img, i) => {
        if (checkIsNotUploaded(img)) {
          toUpload.push(img);
          tempProject.thumbnailImages[i] = count++;
        }
      });

    Array.isArray(tempProject.furtherImages) &&
      tempProject.furtherImages?.map((img, i) => {
        if (checkIsNotUploaded(img)) {
          toUpload.push(img);
          tempProject.furtherImages[i] = count++;
        }
      });

    return { toUpload, project: tempProject, total: count };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getSpecificUrl = (data, urls) => {
  return "";
};

const setUploadedImgUrls = (projectObj, urls) => {
  try {
    if (typeof projectObj?.featuredImage === "number") {
      projectObj.featuredImage = urls[projectObj.featuredImage];
    }

    if (typeof projectObj?.bannerImage === "number") {
      projectObj.bannerImage = urls[projectObj.bannerImage];
    }
    if (typeof projectObj?.keyHighlightImage === "number") {
      projectObj.keyHighlightImage = urls[projectObj.keyHighlightImage];
    }
    if (typeof projectObj?.mainImage === "number") {
      projectObj.mainImage = urls[projectObj.mainImage];
    }
    Array.isArray(projectObj.thumbnailImages) &&
      projectObj.thumbnailImages?.map((img, i) => {
        if (typeof img === "number") {
          projectObj.thumbnailImages[i] = urls[projectObj.thumbnailImages[i]];
        }
      });

    Array.isArray(projectObj.furtherImages) &&
      projectObj.furtherImages?.map((img, i) => {
        if (typeof img === "number") {
          projectObj.furtherImages[i] = urls[projectObj.furtherImages[i]];
        }
      });
    return projectObj;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const s3uploads = async (toUpload, getStatus, pathname) => {
  let uploaded = 0;

  if (!Array.isArray(toUpload)) return [];

  const uploadPromises = toUpload.map(async (data) => {
    try {
      const params = {
        Bucket: s3BucketName,
        Key: `projects/${pathname || "default"}/${data?.file?.name}`,
        Body: data?.file,
        ACL: "public-read", // Change the ACL as per your requirement
      };

      const { Location, Key } = await s3.upload(params).promise();
      getStatus(`Uploading images... [${++uploaded}/${toUpload.length}]`);
      const cloudfrontUrl = Location.replace(
        process.env.REACT_APP_AWS_UPLOADED_FILE_URL_LINK,
        process.env.REACT_APP_CDN_URL
      );

      return cloudfrontUrl;
    } catch (error) {
      return "";
    }
  });
  const uploadedUrls = await Promise.all(uploadPromises);
  return uploadedUrls;
};

const uploadImagesOfProject = async (project, getStatus) => {
  try {
    getStatus("Initializing image upload");
    let tempProject = { ...project };
    const blobPathId =
      project?.blobPathId || Math.floor(Math.random() * 1000000000000);

    const {
      toUpload,
      project: projectObj,
      total,
    } = getImagesToUpload(tempProject);

    const urls = await s3uploads(toUpload, getStatus, blobPathId);

    const resultProject = setUploadedImgUrls(projectObj, urls);
    resultProject.blobPathId = blobPathId;

    getStatus("Images uploaded successfully");

    return resultProject;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default uploadImagesOfProject;
