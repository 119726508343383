import styles from "./style.module.css";
import React, { useState, useEffect } from "react";
import { createContext } from "react";
import { useContext } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { ImageUploadWithOutput } from "../utils/ImageUpload";
import MyButton from "../Atoms/Button";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getNames } from "country-list";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchIndustryTypes } from "../../lib/api/project/industryType";
import {
  createUnpublishedProject,
  editUnpublishedProject,
} from "../../lib/api/project/unpublished";
import * as yup from "yup";
import { fetchUnpublishedProject } from "../../lib/api/project/unpublished";
import {
  createProject,
  editProject,
  fetchProject,
} from "../../lib/api/project/published";
import { publishUnpublishedProject } from "../../lib/api/project/unpublished";
import uploadImagesOfProject from "./helper";
import dayjs from "dayjs";

const projectSchema = yup.object({
  projectName: yup.string().required("Project Name is required"),
  clientName: yup.string().required("Client Name is required"),
  country: yup.string().required("Country is required"),
  area: yup.string().required("Area is required"),
  industryType: yup.string().required("Industry Type is required"),
  year: yup.string().required("Year is required"),
  executionTime: yup.string().required("Execution Time is required"),
  addObjectives: yup.string().required("Objectives is required"),
  projectDetails: yup.string().required("Description is required"),
  featuredImage: yup.mixed().required("Featured thumbnail Image is required"),
  bannerImage: yup.mixed().required("Banner Image is required"),
  // keyHighlights: yup
  //   .array()
  //   .of(yup.string().required("Key highlight points are required"))
  //   .min(3, "At least three key highlight points are required"),

  // keyHighlightImage: yup.mixed().required("Key Highlights Image is required"),
  // mainImage: yup.mixed().required("Main Image is required"),
  // thumbnailImages: yup
  //   .array()
  //   .of(yup.mixed().required("Tumbnail Image is required"))
  //   .min(1, "At least one image is required"),
});

const draftProjectSchema = yup.object({
  projectName: yup.string().required("Project Name is required"),

  industryType: yup.string().required("Industry Type is required"),
});

export const ProjectContext = createContext();

const CreateEditProject = ({}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({});
  const [isError, setIsError] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);

  const handleSaveAsDraft = async (e) => {
    try {
      setLoading("drafting");
      await draftProjectSchema.validate(projectData, {
        abortEarly: false,
      });
      const result = await uploadImagesOfProject(projectData, setUploadStatus);

      projectData?.id
        ? await editUnpublishedProject(projectData?.id, result)
        : await createUnpublishedProject(result);
      navigate("/dashboard?type=unpublished");
    } catch (error) {
      const errorsObject = error?.inner?.reduce((acc, validationError) => {
        return { ...acc, [validationError.path]: validationError.message };
      }, {});
      setIsError(errorsObject);
      let uploadstatus =
        error?.response?.data?.msg ||
        (errorsObject
          ? "Required fields are not filled"
          : "Something went wrong! Please try again.");
      setUploadStatus(uploadstatus);
      setLoading(false);
    }
  };

  const handlePublish = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);
      await projectSchema.validate(projectData, {
        abortEarly: false,
      });
      const result = await uploadImagesOfProject(projectData, setUploadStatus);
      if (projectData?.id) {
        projectData.isPublished
          ? await editProject(projectData?.id, result)
          : await publishUnpublishedProject(projectData?.id, {
              ...result,
              isEdited: true,
            });
      } else {
        await createProject(result);
      }
      navigate("/dashboard");
    } catch (error) {
      const errorsObject = error?.inner?.reduce((acc, validationError) => {
        return { ...acc, [validationError.path]: validationError.message };
      }, {});
      let uploadstatus =
        error?.response?.data?.msg ||
        (errorsObject
          ? "Required fields are not filled"
          : "Something went wrong! Please try again.");
      setUploadStatus(uploadstatus);

      setIsError(errorsObject);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchPublishedProject = async (id) => {
    try {
      setLoading("fetching");
      const res = await fetchProject(id);
      setProjectData(res?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleFetchUnpublishedProject = async (id) => {
    try {
      setLoading("fetching");
      const res = await fetchUnpublishedProject(id);
      setProjectData(res?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const id = searchParams.get("id");
    const isPublished = searchParams.get("isPublished") === "true";
    if (id) {
      isPublished
        ? handleFetchPublishedProject(id)
        : handleFetchUnpublishedProject(id);
    } else {
      let data = {
        isGlobal: searchParams.get("isGlobal"),
        thumbnailImages: [],
        furtherImages: [],
      };
      if (data.isGlobal != "true") {
        data.isGlobal = false;
        data.country = "India";
      }
      setProjectData(data);
    }
  }, []);

  if (projectData)
    return (
      <ProjectContext.Provider
        value={{
          projectData,
          setProjectData,
          isError,
          setIsError,
          loading,
          setLoading,
        }}
      >
        {loading === "fetching" ? (
          <div className={styles["fetching-project"]}>
            <CircularProgress
              sx={{
                color: "var(--red)",
              }}
            />
          </div>
        ) : (
          <div className={styles["container-main"]}>
            <div className={styles["logo-contianer"]}>
              <img
                src={`assets/images/company_logo.png`}
                alt="logo"
                className={styles.logo}
              />
            </div>

            <div>
              <div className={styles["featured-thumbnail-container"]}>
                <span>
                  <div>
                    <p>**Featured Static Thumbnail: </p>
                    {isError?.featuredImage && (
                      <p className={styles["input-error"]}>
                        {isError.featuredImage}
                      </p>
                    )}
                  </div>
                  <ImageUploadWithOutput
                    name="featuredImage"
                    value={projectData?.featuredImage}
                    onChange={(url) =>
                      setProjectData({ ...projectData, featuredImage: url })
                    }
                    onDelete={() =>
                      setProjectData({ ...projectData, featuredImage: null })
                    }
                    accept="image/*"
                  >
                    Upload Image
                  </ImageUploadWithOutput>
                </span>
              </div>
              <InputType1 />
              <KeyHighLights />
              <MainImage />
              <ThumbNailImage />
              <AddMoreMainImage />
              <div className={styles["publish-nd-cancelBtn-container"]}>
                {isError?.error && (
                  <div className={styles["error"]}>
                    <p className={styles["input-error"]}>
                      Mendatory fields are not filled
                    </p>
                  </div>
                )}
                <div className={styles["publish-nd-cancelBtn"]}>
                  <button
                    onClick={handlePublish}
                    style={{ backgroundColor: "#1ABA22" }}
                    disabled={loading}
                  >
                    {loading === "publishing"
                      ? projectData?.isPublished
                        ? "Saving.."
                        : "Publishing"
                      : projectData?.isPublished
                      ? "Save"
                      : "Publish"}
                  </button>

                  {!projectData?.isPublished && (
                    <button
                      style={{ background: "#E86026" }}
                      onClick={handleSaveAsDraft}
                      disabled={loading}
                    >
                      {loading === "drafting" ? "Saving..." : "Save As Draft"}
                    </button>
                  )}

                  <button
                    onClick={() =>
                      navigate(
                        `/dashboard${
                          projectData.isPublished ? "" : "?type=unpublished"
                        }`
                      )
                    }
                    type="button"
                    style={{ backgroundColor: "#EC2227" }}
                  >
                    Cancel
                  </button>
                </div>
                <p>{uploadStatus}</p>
              </div>
            </div>
          </div>
        )}
      </ProjectContext.Provider>
    );
  else return <p>404! Project Not Found.</p>;
};

export default CreateEditProject;

const InputType1 = () => {
  const { projectData, setProjectData, isError } = useContext(ProjectContext);
  return (
    <>
      <div className={styles["input-part-1"]}>
        <div>
          <label>1. Project Name :</label>
          <input
            type="text"
            name="projectName"
            value={projectData?.projectName}
            placeholder="Insert Project Name"
            onChange={(e) =>
              setProjectData({
                ...projectData,
                projectName: e.target.value.replace(/\n/g, " "),
              })
            }
            disabled={false}
          />
          {isError?.projectName && (
            <p className={styles["input-error"]}>{isError.projectName}</p>
          )}
        </div>
        <div>
          <label>2. Client Name :</label>
          <input
            type="text"
            name="clientName"
            value={projectData?.clientName}
            placeholder="Insert client Name"
            onChange={(e) =>
              setProjectData({
                ...projectData,
                clientName: e.target.value.replace(/\n/g, " "),
              })
            }
            disabled={false}
          />
          {isError?.clientName && (
            <p className={styles["input-error"]}>{isError.clientName}</p>
          )}
        </div>
        <div className={styles["row-container"]}>
          <SelectCountry />
          <div>
            <label>4. Area :</label>
            <input
              type="text"
              name="area"
              value={projectData?.area}
              placeholder="Insert area Name"
              onChange={(e) =>
                setProjectData({
                  ...projectData,
                  area: e.target.value.replace(/\n/g, " "),
                })
              }
              disabled={false}
            />
            {isError?.area && (
              <p className={styles["input-error"]}>{isError.area}</p>
            )}
          </div>
        </div>
        <div className={styles["row-container"]}>
          <SelectIndustryType />
          <div className={styles["date-picker-container"]}>
            <label className={styles["label"]}>6. Year</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label={"Select month and year"}
                  views={["month", "year"]}
                  name="year"
                  onChange={(val) =>
                    setProjectData({ ...projectData, year: val })
                  }
                  value={dayjs(projectData?.year)}
                />
              </DemoContainer>
            </LocalizationProvider>
            {isError?.year && (
              <p className={styles["input-error"]}>{isError?.year}</p>
            )}
          </div>
        </div>

        <div>
          <label>7. Execution Time</label>
          <input
            type="text"
            name="executionTime"
            value={projectData?.executionTime}
            placeholder=""
            onChange={(e) =>
              setProjectData({
                ...projectData,
                executionTime: e.target.value.replace(/\n/g, " "),
              })
            }
            disabled={false}
          />
          {isError?.executionTime && (
            <p className={styles["input-error"]}>{isError.executionTime}</p>
          )}
        </div>

        <div>
          <label>8. Add Objectives : </label>
          <textarea
            type="text"
            name="objective"
            value={projectData?.addObjectives}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                addObjectives: e.target.value.replace(/\n/g, " "),
              })
            }
            disabled={false}
            placeholder="Enter your Objectives"
          />
          {isError?.addObjectives && (
            <p className={styles["input-error"]}>{isError.addObjectives}</p>
          )}
        </div>
        <div>
          <label>9. Project Details : </label>
          <textarea
            type="text"
            name="projectDetails"
            value={projectData?.projectDetails}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                projectDetails: e.target.value.replace(/\n/g, " "),
              })
            }
            disabled={false}
            placeholder="Enter your Description"
          />
          {isError?.projectDetails && (
            <p className={styles["input-error"]}>{isError.projectDetails}</p>
          )}
        </div>
        <div className={styles["upload-container"]}>
          <label>10. Banner Image</label>
          <p>Please use dimension for banner Image : 1080*650px</p>
          <div className={styles["upload-img-btn"]}>
            <ImageUploadWithOutput
              name="bannerImage"
              value={projectData?.bannerImage}
              onChange={(url) =>
                setProjectData({ ...projectData, bannerImage: url })
              }
              onDelete={() =>
                setProjectData({ ...projectData, bannerImage: null })
              }
              accept="image/*"
            >
              Upload Image
            </ImageUploadWithOutput>
          </div>
          {isError?.bannerImage && (
            <p className={styles["input-error"]}>{isError.bannerImage}</p>
          )}
        </div>
      </div>
    </>
  );
};

const SelectIndustryType = () => {
  const [industryTypes, setIndustryTypes] = useState([]);
  const { projectData, setProjectData, isError } = useContext(ProjectContext);

  const handleFetchIndustryType = async () => {
    try {
      const data = await fetchIndustryTypes();
      setIndustryTypes(data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    handleFetchIndustryType();
  }, []);

  return (
    <>
      <div>
        <label>5. Industry type:</label>
        <select
          name="industryType"
          value={projectData?.industryType}
          onChange={(e) =>
            setProjectData({ ...projectData, industryType: e.target.value })
          }
        >
          <option>Select Industry Type</option>
          {Array.isArray(industryTypes) &&
            industryTypes.map((item, i) => (
              <option
                value={item.id}
                key={i}
                className={styles["option-style"]}
              >
                {item.industryTypeName}
              </option>
            ))}
        </select>
        {isError?.industryType && (
          <p key={234} className={styles["input-error"]}>
            {isError.industryType}
          </p>
        )}
      </div>
    </>
  );
};

const SelectCountry = () => {
  const { projectData, setProjectData, isError } = useContext(ProjectContext);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allCountries = await getNames();
        if (allCountries) {
          const filteredCountries = allCountries.filter(
            (country) => country !== "India"
          );
          setCountries(filteredCountries);
        }
      } catch (error) {}
    };

    fetchCountries();
  }, []);

  const handleCountryChange = (e) => {
    setProjectData({ ...projectData, country: e.target.value });
  };

  return (
    <div>
      <label>3. Select Country:</label>
      {projectData?.isGlobal ? (
        <>
          <select
            value={projectData?.country}
            onChange={handleCountryChange}
            name="country"
          >
            <option value="">All Countries</option>
            {countries?.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
          {isError?.country && (
            <p className={styles["input-error"]}>{isError.country}</p>
          )}
        </>
      ) : (
        <>
          <div>
            <input type="text" name="country" value="India" disabled={false} />
          </div>
        </>
      )}
    </div>
  );
};

const KeyHighLights = () => {
  const [inputs, setInputs] = useState(["", "", ""]);
  const { projectData, setProjectData, isError } = useContext(ProjectContext);

  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
    setProjectData({
      ...projectData,
      keyHighlights: newInputs,
    });
  };

  const addInputField = () => {
    setInputs([...inputs, ""]);
  };
  const deleteInputField = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  useEffect(() => {
    if (
      projectData &&
      projectData.keyHighlights &&
      projectData.keyHighlights.length > 0
    ) {
      setInputs(projectData.keyHighlights);
    }
  }, []);

  return (
    <>
      <div className={styles["key-highlights"]}>
        <label>11. Key Highlishts: </label>
        <div className={styles["inputs"]}>
          {inputs.map((input, index) => (
            <div>
              <input
                name="keyHighlights"
                key={index}
                type="text"
                value={inputs[index]}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Enter Key highlights here"
              />
              {inputs.length > 3 && (
                <IconButton onClick={() => deleteInputField(index)}>
                  <CloseIcon style={{ color: "var(--red)" }} />
                </IconButton>
              )}
            </div>
          ))}
          {isError?.keyHighlights && (
            <p key={234} className={styles["input-error"]}>
              {isError.keyHighlights}
            </p>
          )}
        </div>
        <div>
          <MyButton onClick={addInputField}>+ Add more Key Highlights</MyButton>
        </div>
      </div>
      <div className={styles["upload-container"]}>
        <label>12. Key Highlight Image (Only 1)</label>
        <p>Please use images for 514*366 PX</p>
        <div className={styles["upload-img-btn"]}>
          <ImageUploadWithOutput
            name="keyHighlightImage"
            value={projectData?.keyHighlightImage}
            onChange={(url) =>
              setProjectData({ ...projectData, keyHighlightImage: url })
            }
            onDelete={() =>
              setProjectData({ ...projectData, keyHighlightImage: null })
            }
            accept="image/*"
          >
            Upload Image
          </ImageUploadWithOutput>
          {isError?.keyHighlightImage && (
            <p className={styles["input-error"]}>{isError.keyHighlightImage}</p>
          )}
        </div>
      </div>
    </>
  );
};

const MainImage = () => {
  const { projectData, setProjectData, isError } = useContext(ProjectContext);

  return (
    <div className={styles["upload-container"]}>
      <label>13. Main Image :</label>
      <p>Please use width for carousel images, gif, video: 1080px </p>
      <div className={styles["upload-img-btn"]}>
        <ImageUploadWithOutput
          name="mainImage"
          value={projectData?.mainImage}
          onChange={(url) => setProjectData({ ...projectData, mainImage: url })}
          onDelete={() => setProjectData({ ...projectData, mainImage: null })}
          accept="image/*"
        >
          Upload Image
        </ImageUploadWithOutput>
        {isError?.mainImage && (
          <p className={styles["input-error"]}>{isError.mainImage}</p>
        )}
      </div>
    </div>
  );
};

const ThumbNailImage = () => {
  const { projectData, setProjectData, isError } = useContext(ProjectContext);
  return (
    <>
      <div className={styles["upload-container"]}>
        <label>14. Thumbnail Images (Max 2 images) :</label>
        <p>Please use width for carousel images: 514px </p>
        <div className={styles["upload-img-btn"]}>
          <ImageUploadWithOutput
            name="thumbnailImages"
            value={projectData?.thumbnailImages?.[0]}
            onChange={(url) =>
              setProjectData({
                ...projectData,
                thumbnailImages: projectData.thumbnailImages?.[1]
                  ? [url, projectData.thumbnailImages?.[1]]
                  : [url],
              })
            }
            onDelete={() =>
              setProjectData({
                ...projectData,
                thumbnailImages: projectData.thumbnailImages?.[1]
                  ? [projectData.thumbnailImages?.[1]]
                  : [],
              })
            }
            accept="image/*"
          >
            Upload Image
          </ImageUploadWithOutput>
          {projectData?.thumbnailImages?.[0] && (
            <ImageUploadWithOutput
              name="thumbnailImages"
              value={projectData?.thumbnailImages?.[1]}
              onChange={(url) =>
                setProjectData({
                  ...projectData,
                  thumbnailImages: [projectData.thumbnailImages?.[0], url],
                })
              }
              onDelete={() =>
                setProjectData({
                  ...projectData,
                  thumbnailImages: [projectData.thumbnailImages?.[0]],
                })
              }
              accept="image/*"
            >
              Upload Image
            </ImageUploadWithOutput>
          )}
          {isError?.thumbnailImages && (
            <p className={styles["input-error"]}>{isError.thumbnailImages}</p>
          )}
        </div>
      </div>
    </>
  );
};

const AddMoreMainImage = () => {
  const [isNewSpace, setIsNewSpace] = useState(false);
  const { projectData, setProjectData, isError } = useContext(ProjectContext);

  const handleGetNewSpace = () => setIsNewSpace(true);

  const handleRemoveImage = (index) => {
    const furtherImages = [...projectData?.furtherImages];
    furtherImages.splice(index, 1);
    setProjectData({ ...projectData, furtherImages });
  };

  const handleAddImage = (val) => {
    setProjectData({
      ...projectData,
      furtherImages: [...projectData?.furtherImages, val],
    });
    setIsNewSpace(false);
  };

  return (
    <div>
      {projectData?.furtherImages?.map((image, index) => (
        <div className={styles["add-more main"]} key={index}>
          <div className={styles["upload-container"]}>
            <label>{`${index + 15} . Main Image`}</label>
            <p>Please use width for carousel images, gif, video: 1080px </p>
            <div className={styles["upload-img-btn"]}>
              <ImageUploadWithOutput
                name={`addedmainImage-${index}`}
                value={image}
                onDelete={() => handleRemoveImage(index)}
                accept="image/*"
              >
                Upload Image
              </ImageUploadWithOutput>
              {isError?.furtherImages && (
                <p className={styles["input-error"]}>{isError.furtherImages}</p>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className={styles["add-more-btn"]}>
        {isNewSpace ? (
          <ImageUploadWithOutput
            name={`newImage`}
            onChange={handleAddImage}
            accept="image/*"
          >
            Upload Image
          </ImageUploadWithOutput>
        ) : (
          <MyButton onClick={handleGetNewSpace}>
            + Add More Main Images
          </MyButton>
        )}
      </div>
    </div>
  );
};
