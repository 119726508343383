// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_btn__irvu6 {
    background-color: var(--red);
    /* width: 100%; */
    padding: 1vw;
    border: none;
    color: #ffff;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 1.5vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5vw
}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/Button/style.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB;AACJ","sourcesContent":[".btn {\n    background-color: var(--red);\n    /* width: 100%; */\n    padding: 1vw;\n    border: none;\n    color: #ffff;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 1.2vw;\n    line-height: 1.5vw;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: .5vw\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `style_btn__irvu6`
};
export default ___CSS_LOADER_EXPORT___;
